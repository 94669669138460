import { Box, Flex, Heading } from '@chakra-ui/layout';
import Head from 'next/head';
import Navigation from '../navigation';
import ProfileNavigation from '../profile/navigation';

type LandingPageProps = {
	title: string;
	description: string;
};

const LandingPage: React.FC<LandingPageProps> = ({
	children,
	title,
	description,
}) => {
	return (
		<>
			<Head>
				<title>{title}</title>
				<meta
					name="description"
					content={description}
				/>
				<meta
					name="author"
					content="Status Code, https://statuscode.nl, martijn@statuscode.nl"
				/>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, shrink-to-fit=no"
				/>
				<link
					rel="icon"
					href="/favicon-32x32.png"
				></link>
			</Head>
			<Box
				as="header"
				position="sticky"
				zIndex={2}
				top={0}
				padding={4}
				// backgroundColor="pink.200"
				backgroundColor="#fbb6ced0"
				boxShadow="xl"
				backdropFilter="auto"
				backdropBlur="md"
				backdropBrightness={1.05}
			>
				<Flex
					justifyContent="space-between"
					alignItems="baseline"
				>
					<Heading size="xl">
						<a href="/">Yoga...</a>{' '}
						<small>
							<a href="/">dat is gezond!</a>
						</small>
					</Heading>
					<Navigation></Navigation>
					<ProfileNavigation></ProfileNavigation>
				</Flex>
			</Box>
			<Box
				as="main"
				padding="4"
			>
				{children}
			</Box>
			<Box as="footer"></Box>
		</>
	);
};

export default LandingPage;
