import { Media } from '@/prisma/utils/media';
import { Box } from '@chakra-ui/layout';
import { AspectRatio } from '@chakra-ui/react';
import NextImage from 'next/image';
import React from 'react';

type MediaThumbnailProps = {
	media: Media;
};

const MediaThumbnail: React.FC<MediaThumbnailProps> = ({ media }) => {
	const { source } = media;
	// const sourceUuid = 'r7xsYgTeM2Q';
	// https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=UEEsdXn8oG8&format=json
	const posterSrc = `http://img.youtube.com/vi/${source.providerUuid}/0.jpg`;
	const ratio = 16 / 9;

	return (
		<Poster
			src={posterSrc}
			ratio={ratio}
		/>
	);
};

type PosterProps = {
	src: string;
	ratio: number;
};

const Poster: React.FC<PosterProps> = ({ src, ratio }) => (
	<AspectRatio
		ratio={ratio}
		backgroundColor="gray.100"
	>
		<NextImage
			src={src}
			layout="fill"
			objectFit="cover"
		></NextImage>
	</AspectRatio>
);

export default MediaThumbnail;
