import { Tag as TagModel } from '@/prisma/utils/tag';
import { Wrap } from '@chakra-ui/layout';
import { Tag, TagLabel } from '@chakra-ui/tag';
import NextLink from 'next/link';

type TagCloudProps = {
	tags: TagModel[];
	hrefPrefix: string;
	colorScheme?: string;
};

const TagCloud: React.FC<TagCloudProps> = ({
	tags,
	hrefPrefix = '/tags',
	colorScheme = 'pink',
}: TagCloudProps) => {
	const tagList = tags.map((tag) => {
		return (
			<NextLink
				key={tag.slug}
				href={`${hrefPrefix}/${tag.slug}`}
				passHref
			>
				<Tag
					as="a"
					// size="lg"
					// borderRadius="full"
					colorScheme={colorScheme}
					_hover={{
						backgroundColor: `${colorScheme}.200`,
					}}
				>
					<TagLabel>{tag.name}</TagLabel>
				</Tag>
			</NextLink>
		);
	});

	return (
		<Wrap
			spacing={2}
			my={2}
		>
			{tagList}
		</Wrap>
	);
};

export default TagCloud;
