import {
	Button,
	Menu,
	MenuButton,
	MenuDivider,
	MenuGroup,
	MenuItem,
	MenuList,
} from '@chakra-ui/react';
import { signIn, signOut, useSession } from 'next-auth/client';
import React from 'react';

const ProfileNavigation: React.FC = () => {
	const [session, loading] = useSession();

	if (loading) {
		return <Button disabled>Loading...</Button>;
	}

	if (!session) {
		return <Button onClick={() => signIn()}>Sign in</Button>;
	}

	return (
		<Menu>
			<MenuButton
				as={Button}
				colorScheme="pink"
			>
				Profile
			</MenuButton>
			<MenuList>
				<MenuGroup title="Profile">
					<MenuItem
						as="a"
						href="/users/me/playlists"
					>
						My playlists
					</MenuItem>
					<MenuItem>My Account</MenuItem>
					<MenuItem>Payments</MenuItem>
					<MenuItem onClick={() => signOut()}>Sign out</MenuItem>
				</MenuGroup>
				<MenuDivider />
				<MenuGroup title="Help">
					<MenuItem>Docs</MenuItem>
					<MenuItem>FAQ</MenuItem>
				</MenuGroup>
			</MenuList>
		</Menu>
	);
};

export default React.memo(ProfileNavigation);
