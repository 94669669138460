import { Media, MediaRequestBody } from '@/prisma/utils/media';
import { MediaType } from '@/prisma/utils/media-type';
import {
	Playlist,
	PlaylistRequestBody,
	PlaylistWithItems,
} from '@/prisma/utils/playlist';
import {
	PlaylistItemsDeleteRequestBody,
	PlaylistItemsPostRequestBody,
	PlaylistItemsPutRequestBody,
} from '@/prisma/utils/playlist-item';
import { ClientSafeUser } from '@/prisma/utils/user';
import { useMutation, useQuery, useQueryClient } from 'react-query';

async function request(uri: string, options = {}) {
	const response = await fetch(uri, options);

	if (!response.ok) {
		let error;

		try {
			error = await response.json();
		} catch (parseError) {
			console.error(parseError);

			throw parseError;
		}

		throw error;
	}

	return response;
}

export function useRecentMedia() {
	return useQuery<Media[], Error>(['media', { recent: true }], async () =>
		(await request('/api/media?take=12&order=latest')).json()
	);
}

export function usePopularMedia() {
	return useQuery<Media[], Error>(['media', { popular: true }], async () =>
		(await request('/api/media?take=12&order=popular')).json()
	);
}

export function useRecentPlaylists() {
	return useQuery<PlaylistWithItems[], Error>(
		['playlists', { recent: true }],
		async () => (await request('/api/playlists?take=12&order=latest')).json()
	);
}

export function usePopularPlaylists() {
	return useQuery<PlaylistWithItems[], Error>(
		['playlists', { popular: true }],
		async () => (await request('/api/playlists?take=12&order=popular')).json()
	);
}

export function useUserPlaylists(user: ClientSafeUser) {
	return useQuery<PlaylistWithItems[], Error>(
		['playlists', { user: user }],
		async () => (await request(`/api/users/${user.id}/playlists`)).json(),
		{ enabled: !!user?.id }
	);
}

export function usePlaylist(id: number) {
	return useQuery<PlaylistWithItems, Error>(['playlists', id], async () =>
		(await request(`/api/playlists/${id}`)).json()
	);
}

export function useMediaTypes() {
	return useQuery<MediaType[], Error>(['mediaTypes'], async () =>
		(await request('/api/media-types')).json()
	);
}

const defaultHeaders = {
	'Content-Type': 'application/json',
};

const useMutationOptions = () => {
	const queryClient = useQueryClient();

	return {
		onMutate: (variables: any) => {
			// A mutation is about to happen!
			console.log('onMutate', variables);

			// Optionally return a context containing data to use when for example rolling back
			// return { id: 1 };
		},
		onError: (error: any, variables: any, context: any) => {
			console.log('onError', error, variables, context);
		},
		onSuccess: async (response: any, variables: any, context: any) => {
			console.log('onSuccess', response, variables, context);
			// const playlist = await response.json();
			queryClient.invalidateQueries('playlists');
			// queryClient.invalidateQueries(['playlists', playlist.id]);
		},
		onSettled: (data: any, error: any, variables: any, context: any) => {
			// Error or success... doesn't matter!
			console.log('onSettled', data, error, variables, context);
		},
	};
};

export function useCreatePlaylist() {
	const options = useMutationOptions();

	return useMutation(
		({ data }: { data: PlaylistRequestBody }) =>
			request('/api/playlists', {
				method: 'POST',
				headers: defaultHeaders,
				body: JSON.stringify(data),
			}),
		options
	);
}

export function useUpdatePlaylist() {
	const options = useMutationOptions();

	return useMutation(
		({ playlist, data }: { playlist: Playlist; data: PlaylistRequestBody }) =>
			request(`/api/playlists/${playlist.id}`, {
				method: 'PUT',
				headers: defaultHeaders,
				body: JSON.stringify(data),
			}),
		options
	);
}

export function useDeletePlaylist() {
	const options = useMutationOptions();

	return useMutation(
		({ playlist }: { playlist: Playlist }) =>
			request(`/api/playlists/${playlist.id}`, {
				method: 'DELETE',
				headers: defaultHeaders,
			}),
		options
	);
}

export function useCreatePlaylistItems() {
	const options = useMutationOptions();

	return useMutation(
		({
			playlist,
			data,
		}: {
			playlist: Playlist;
			data: PlaylistItemsPostRequestBody;
		}) =>
			request(`/api/playlists/${playlist.id}/items`, {
				method: 'POST',
				headers: defaultHeaders,
				body: JSON.stringify(data),
			}),
		options
	);
}

export function useUpdatePlaylistItems() {
	const options = useMutationOptions();

	return useMutation(
		({
			playlist,
			data,
		}: {
			playlist: Playlist;
			data: PlaylistItemsPutRequestBody;
		}) =>
			request(`/api/playlists/${playlist.id}/items`, {
				method: 'PUT',
				headers: defaultHeaders,
				body: JSON.stringify(data),
			}),
		options
	);
}

export function useDeletePlaylistItems() {
	const options = useMutationOptions();

	return useMutation(
		({
			playlist,
			data,
		}: {
			playlist: Playlist;
			data: PlaylistItemsDeleteRequestBody;
		}) =>
			request(`/api/playlists/${playlist.id}/items`, {
				method: 'DELETE',
				headers: defaultHeaders,
				body: JSON.stringify(data),
			}),
		options
	);
}

export function useCreateMedia() {
	const options = useMutationOptions();

	return useMutation(
		async ({ data }: { data: MediaRequestBody }) =>
			(
				await request('/api/media', {
					method: 'POST',
					headers: defaultHeaders,
					body: JSON.stringify(data),
				})
			).json(),
		options
	);
}

export function useUpdateMedia() {
	const options = useMutationOptions();

	return useMutation(
		({ media, data }: { media: Media; data: MediaRequestBody }) =>
			request(`/api/media/${media.id}`, {
				method: 'PUT',
				headers: defaultHeaders,
				body: JSON.stringify(data),
			}),
		options
	);
}
